import { motion } from 'framer-motion';
import React from 'react';
import styled, { css } from 'styled-components';
import { rem } from 'styles';
import { MODAL_SIZES } from 'styles/tokens';
import Modal from '.';

type ModalSizeType = keyof typeof MODAL_SIZES;

const getModalSize = (size: ModalSizeType) => {
	switch (size) {
		case MODAL_SIZES.sm:
			return rem(400);
		case MODAL_SIZES.md:
			return rem(800);
		case MODAL_SIZES.lg:
			return rem(1200);
		default:
			return rem(800);
	}
};

export type ModalProps = {
	isOpen: boolean;
	closeOnEscOrClickOutside?: boolean;
	hideModal: () => void;
	children?: React.ReactNode;
	size?: ModalSizeType;
	initialFocus?: React.MutableRefObject<HTMLElement | null> | undefined;
	zIndex?: number;
	padding?: string;
};

const StyledGradient = styled.div<{ padding?: string }>(
	({ theme: { spacings }, padding }) => css`
		background: ${({ theme }) => theme.gradients.primary};
		padding: ${padding ? padding : spacings[40]};
	`
);

const StyledContainer = styled.div<{ size: ModalSizeType }>(
	({ theme, size = 'md' }) => {
		const { spacings } = theme;
		return css`
			display: inline-block;
			position: relative;
			width: ${getModalSize(size)};
			height: auto;
			background-color: white;
			border-radius: ${spacings[4]};
			margin: ${spacings[20]} auto;
		`;
	}
);

const variants = {
	hidden: { opacity: 0, x: 0, y: -50 },
	enter: { opacity: 1, x: 0, y: 0 },
	exit: { opacity: 0, x: 0, y: -50 },
};

const SimpleModal = ({
	isOpen,
	hideModal,
	children,
	size = 'md',
	padding,
	...rest
}: ModalProps) => (
	<Modal isOpen={isOpen} hideModal={hideModal} {...rest}>
		<motion.div
			variants={variants}
			initial='hidden'
			animate='enter'
			exit='exit'
			transition={{ type: 'linear', duration: 0.2 }}
		>
			<StyledContainer size={size}>
				<StyledGradient padding={padding}>{children}</StyledGradient>
			</StyledContainer>
		</motion.div>
	</Modal>
);

export default SimpleModal;
