import styled, { css } from 'styled-components';
import { rem } from 'styles';

const PreviewCardContainer = styled.div(({ theme }) => {
	const { spacings, colors } = theme;
	return css`
		padding: ${spacings[10]} ${spacings[20]};
		background-color: ${colors.white[500]};
		border: ${spacings[1]} solid ${colors.primary[100]};
		min-width: ${rem(460)};
	`;
});

const FormContainer = styled.form`
	max-width: ${rem(440)};
	margin: auto;
`;

export { FormContainer, PreviewCardContainer };
