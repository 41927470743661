import { Dialog } from '@headlessui/react';
import React, { CSSProperties, ReactNode } from 'react';
import { useTheme } from 'styled-components';

type ModalProps = {
	isOpen: boolean;
	closeOnEscOrClickOutside?: boolean;
	hideModal: () => void;
	children: ReactNode;
	style?: CSSProperties;
	initialFocus?: React.MutableRefObject<HTMLElement | null> | undefined;
	zIndex?: number;
	isFullScreen?: boolean;
};

const dialogStyles: CSSProperties = {
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	position: 'fixed',
	flexWrap: 'wrap',
	flexDirection: 'row',
	top: '0',
	left: '0',
	right: '0',
	bottom: '0',
	width: '100vw',
	margin: 0,
	overflowY: 'auto',
	zIndex: 10,
	height: '100vh',
};

const Modal = ({
	isOpen,
	closeOnEscOrClickOutside = false,
	hideModal,
	children,
	zIndex = 10,
	isFullScreen = false,
	...rest
}: ModalProps) => {
	const { spacings } = useTheme();
	return (
		<Dialog
			as='div'
			open={isOpen}
			onClose={() => closeOnEscOrClickOutside && hideModal()}
			style={{
				...dialogStyles,
				zIndex,
				borderRadius: spacings[8],
			}}
			{...rest}
		>
			<Dialog.Overlay
				style={{
					position: 'fixed',
					inset: '0',
					backgroundColor: 'black',
					opacity: isFullScreen ? '0' : '0.5',
				}}
			/>
			{children}
		</Dialog>
	);
};

export default Modal;
