import { Web3Provider } from '@ethersproject/providers';
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { LOGIN_MODAL } from 'common/auth/LoginModal';
import { modalActions } from 'common/modal';
import { endpoints } from 'constants/endpoints';
import paths from 'constants/paths';
import { queryKeys } from 'constants/queryKeys';
import { verifyMessage } from 'ethers/lib/utils';
import { useAppSelector, useWeb3Onboard } from 'hooks';
import { useAnalytics } from 'hooks/useAnalytics';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import {
	dashboardActions,
	selectDashboard,
} from 'screens/organisations/dashboard/Dashboard.slice';
import { decodeJWT } from 'utils/auth';
import { addQueryParams, authAxios } from 'utils/axios';
import {
	ERROR_CODES,
	WALLET_ERROR_CODES,
	getErrorCode,
	getErrorMessage,
	handleGenericError,
} from 'utils/error';
import { showError } from 'utils/toast';

export type JwtPayload = {
	exp: number;
	iat: number;
	id: string;
	walletAddress: string;
};

type VerifyAuthResponse = {
	status: boolean;
	name: string;
	email: string;
};

const getStatsAPI = async () => {
	const { data } = await axios.get<{
		data: { accCount: number; orgCount: number; userCount: number };
	}>(endpoints.stats.get);

	let { accCount, orgCount, userCount } = data.data;

	// TODO remove hardcoded values
	if (accCount >= 646) accCount = Math.round((accCount - 646) / 2 + 347);
	if (orgCount >= 506) orgCount = Math.round((orgCount - 506) / 2 + 154);
	if (userCount >= 1283) userCount = Math.round((userCount - 1283) / 2 + 879);

	return {
		accCount,
		orgCount,
		userCount,
	};
};

const useStatsQuery = () => {
	return useQuery(queryKeys.coinshift.stats(), getStatsAPI, {
		onError: handleGenericError,
	});
};

const authTokenAPI = async (walletAddress?: string) => {
	const { data } = await authAxios().get(
		addQueryParams(endpoints.users.verify, { walletAddress })
	);

	return data.data;
};

const useAuthTokenQuery = () => {
	const { account, connectedWallet } = useWeb3Onboard();
	const { token } = useAppSelector(selectDashboard);
	const { identify } = useAnalytics();

	return useQuery(
		queryKeys.users.addressAuthToken(account),
		async () => await authTokenAPI(account),
		{
			enabled: false,
			onSuccess: (data: VerifyAuthResponse) => {
				if (data.status && token) {
					const { id: userId } = decodeJWT(token) as JwtPayload;
					const { name, email } = data;
					identify(userId, {
						name,
						email,
						walletAddress: account,
						walletName: connectedWallet?.label,
					});
				}
			},
			onError: handleGenericError,
		}
	);
};

const generateSignInKeyAPI = (walletAddress: string) =>
	axios.get(
		addQueryParams(endpoints.users.generateSigninKey, { walletAddress })
	);

const loginAPI = async (
	provider: Web3Provider | undefined,
	account: string | undefined
) => {
	if (!provider || !account) throw new Error('No provider or account');

	const response = await generateSignInKeyAPI(account);
	const signinKey = response.data.data;

	const signature = await provider.getSigner(account).signMessage(signinKey);
	const walletAddress = verifyMessage(signinKey, signature);

	const { data } = await axios.post(endpoints.users.login, {
		walletAddress,
		signature,
		signinKey,
	});

	return data;
};

const useLoginMutation = ({ redirectUrl }: { redirectUrl?: string }) => {
	const { provider, account } = useWeb3Onboard();
	const router = useRouter();
	const dispatch = useDispatch();

	return useMutation(() => loginAPI(provider, account), {
		onSuccess: (data) => {
			window.localStorage.setItem('token', data.data);

			if (redirectUrl) router.push(redirectUrl);
			else {
				dispatch(modalActions.hide(LOGIN_MODAL));
				dispatch(dashboardActions.setToken(data.data));
			}
		},
		onError: (error: { code?: number }) => {
			if (error.code === WALLET_ERROR_CODES.USER_DENIED_MESSAGE_SIGNATURE) {
				showError('Wallet signature denied');
				return;
			}

			const errorCode = getErrorCode(error);

			if (
				errorCode === ERROR_CODES.USER_NOT_EXIST ||
				errorCode === ERROR_CODES.SIGNUP_PROCESS_INCOMPLETE
			) {
				showError("User doesn't exist, please sign up");
				router.push({ pathname: paths.signup, query: router.query });
				window.localStorage.removeItem('token');
			} else showError(getErrorMessage(error));
		},
	});
};

export {
	authTokenAPI,
	generateSignInKeyAPI,
	useAuthTokenQuery,
	useLoginMutation,
	useStatsQuery,
};
