import { ModalControlProps } from 'common/modal';
import connectModal from 'common/modal/connectModal';
import Button from 'components/Button';
import SimpleModal from 'components/Modal/SimpleModal';
import Stack from 'components/Stack';
import Subtitle1 from 'components/Typography/Subtitle1';
import Title from 'components/Typography/Title';
import { useWeb3Onboard } from 'hooks';
import { useRef } from 'react';
import { useLoginMutation } from 'screens/login/Login.queries';
import { useTheme } from 'styled-components';
import { getAddressFromToken } from 'utils/auth';
import WalletPreviewCard from './WalletPreviewCard';

export const LOGIN_MODAL = 'LOGIN_MODAL';

const LoginModal = ({ isOpen, hideModal }: ModalControlProps) => {
	const { spacings } = useTheme();
	const { account } = useWeb3Onboard();
	const loginBtnRef = useRef(null);

	const loggedInAddress = getAddressFromToken(
		window.localStorage?.getItem('token')
	);

	const { mutate: logIn, isLoading: isLogIn } = useLoginMutation({});

	return (
		<SimpleModal
			initialFocus={loginBtnRef}
			isOpen={isOpen}
			hideModal={hideModal}
			size='md'
		>
			<Stack alignItems={'center'} gutter={spacings[40]}>
				<Stack alignItems={'center'} gutter={spacings[20]}>
					<Title>Log In</Title>
					<Subtitle1 align='center'>
						Verify your current wallet address or switch to a logged in wallet
						address.
					</Subtitle1>
				</Stack>

				<Stack gutter={spacings[30]}>
					<Stack gutter={spacings[20]} alignItems={'center'}>
						<WalletPreviewCard
							account={account}
							text='Currently connected wallet'
						/>
						<WalletPreviewCard
							account={loggedInAddress}
							text='Logged in wallet'
						/>
					</Stack>
				</Stack>

				<Button
					onClick={() => logIn()}
					isLoading={isLogIn}
					disabled={isLogIn}
					ref={loginBtnRef}
				>
					Log In
				</Button>
			</Stack>
		</SimpleModal>
	);
};

export default connectModal(LOGIN_MODAL)(LoginModal);
