import Address from 'components/Address';
import Avatar from 'components/Avatar';
import Row from 'components/Row';
import Stack from 'components/Stack';
import Typography from 'components/Typography';
import React from 'react';
import { PreviewCardContainer } from 'screens/organisations/dashboard/contacts/ManageContacts.styles';
import { useTheme } from 'styled-components';

const WalletPreviewCard = ({
	account,
	text,
}: {
	account?: string;
	text: string;
}) => {
	const { spacings } = useTheme();
	if (!account) return null;
	return (
		<PreviewCardContainer>
			<Row rowGap={spacings[10]}>
				<Avatar color='primary' title={''} initialsLength={1} size='lg' />
				<Stack gutter={spacings[4]}>
					<Address
						address={account}
						withPrefix
						withCopy={false}
						typographyProps={{
							size: 'sm',
						}}
					/>
					<Typography size='xs' color='textSecondary'>
						{text}
					</Typography>
				</Stack>
			</Row>
		</PreviewCardContainer>
	);
};

export default WalletPreviewCard;
