import Button from 'components/Button';
import { useWeb3Onboard } from 'hooks';
import React from 'react';

const ConnectButton = ({ style }: { style?: React.CSSProperties }) => {
	const { connectWallet, connecting } = useWeb3Onboard();

	return (
		<Button
			onClick={() => connectWallet({})}
			style={style}
			isLoading={connecting}
			disabled={connecting}
		>
			Connect Wallet
		</Button>
	);
};

export default ConnectButton;
