/*
-------------------------
IMPORTANT NOTE
-------------------------
Please use the action-object framework to name events.
Every event will start with an action in present tense (eg. click, view, create)
followed by an object (eg. sidebar, organisation, report).

For naming event, use all lowercase
For naming properties, use camelCase
For eg.
track('click log in', {
	walletAddress: '0x0066E73946B725EC9351759aBC51C30465f55000',
	walletName: 'MetaMask',
});
 */

export const events = {
	user: {
		login: 'click log in',
		signup: 'click sign up',
	},
	organisation: {
		create: 'create organisation',
	},
	dashboard: {
		root: 'land on organisation',
		sidebar: 'click sidebar',
		overview: {
			newFeature: 'click explore new feature in overview',
		},
		portfolio: {
			root: 'land on portfolio',
			tokensView: 'select live portfolio view',
			nftsView: 'select live nfts view',
		},
		reports: {
			root: 'land on reports',
			portfolioHistory: {
				createReport: 'create new portfolio history report',
				downloadCsv: 'download portfolio history csv report',
			},
			cashFlowReporting: {
				downloadReport: 'download cash flow report',
				applyFilters: 'apply cash flow filters',
			},
		},
		transactions: {
			root: 'land on transactions',
			attachLabels: 'attach labels to transaction',
			attachNotes: 'attach notes to transaction',
			exportTxHistory: 'export transaction history csv',
			duplicateTx: 'click duplicate transaction',
		},
		accounts: {
			editName: 'edit account name',
		},
		contacts: {
			addNew: 'add new contact',
			edit: 'edit contact',
			import: 'import contacts',
		},
		settings: {
			root: 'land on settings',
			members: {
				invite: 'invite members from settings',
				addNew: 'add member from settings',
			},
		},
		addAccounts: {
			root: 'land on add accounts',
			importAccount: 'click add existing account',
			createSafe: 'click create new safe',
			addReadOnlyAccounts: 'add read only accounts',
			addMoreMembers: 'add more members',
			finishImport: 'finish import accounts',
			finishCreate: 'finish create safe',
		},
		newTransfer: {
			root: 'land on new transfer',
			massPayout: {
				root: 'click mass payout',
				selectContacts: 'select contacts for mass payout',
				uploadCsv: 'upload csv for mass payout',
				finish: 'finish create mass payout',
			},
			streamPayout: {
				root: 'click stream payout',
				selectContacts: 'select contacts for stream payout',
				uploadCsv: 'upload csv for stream payout',
				finish: 'finish create stream payout',
			},
		},
		transactionFlow: {
			approveProposal: 'approve proposal',
			discardProposal: 'discard proposal',
			approveTx: 'approve transaction',
			rejectTx: 'reject transaction',
			approveExecuteTx: 'approve and execute transaction',
			rejectExecuteTx: 'reject and execute transaction',
			duplicate: 'click duplicate transaction',
		},
		coinshiftApps: {
			openApp: 'open app',
			failedToLoadIframe: 'failed to load iframe',
			enrichmentFailed: 'enrichment has empty response',
			finish: 'finish create coinshift apps transaction',
		},
		notifications: {
			connect: 'connect notification platform',
			disconnect: 'disconnect notification platform',
		},
	},
};
